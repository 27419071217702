<template>
  <b-tabs
    id="account-page-tabs"
    class="mb-5"
    lazy
  >
    <b-tab>
      <template #title>
        <feather-icon icon="ShieldIcon" />
        <div class="font-small-1 tab-title">
          {{ $t('Security') }}
        </div>
      </template>
      <b-row v-if="!loading">
        <b-col cols="12">
          <password-card />
        </b-col>
      </b-row>
    </b-tab>

  </b-tabs>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs,
} from 'bootstrap-vue'
import { notifyError } from '@/utils/methods'
import PasswordCard from '@/views/foravila/account/components/PasswordCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    PasswordCard,
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('foravilaGuestsUserData'))
    },
    clientUuid() {
      return this.userData.client.uuid
    },
    loading() {
      return this.$store.getters['client/loading']
    },
    client() {
      return this.$store.getters['client/client']
    },
  },
  created() {
    if (!this.client) this.fetchClient()
  },
  methods: {
    fetchClient() {
      this.$store
        .dispatch('client/fetchClient', this.clientUuid)
        .catch(() => notifyError(this.$t('errors.fetchBookings.title'), this.$t('errors.fetchBookings.text')))
    },
  },
}
</script>

<style lang="scss">
#account-page-tabs {
  .nav {
    padding-bottom: 10px;
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    flex-wrap: nowrap;
    list-style: none;
    white-space: nowrap;
  }
    // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .nav-tabs::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
  .nav-link {
    display: block !important;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .nav-link::after {
    background: none!important;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .nav-link::after {
      background: linear-gradient(30deg, #ffcd00, rgba(255, 205, 0, 0.5)) !important;
    }
  }
  // Large devices (desktops, 992px and u
  @media (min-width: 992px) {
    .tab-title {
      font-size: 1rem !important;
    }
  }

}
</style>
