<template>
  <b-overlay
    :show="loading"
    variant="white"
    :opacity="0.9"
  >
    <b-card :title="$t('pages.account.security.updatePassword')">
      <validation-observer
        ref="updatePasswordFormRules"
        #default="{invalid}"
      >
        <b-form @submit.prevent="validateForm">
          <b-row>
            <!-- current password -->
            <b-col
              cols="12"
              lg="4"
            >
              <b-form-group :label="$t('pages.account.security.currentPassword')">
                <validation-provider
                  #default="{ errors }"
                  name="currentPassword"
                  vid="currentPassword"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model="currentPassword"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      autocomplete="current-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- new password -->
            <b-col
              cols="12"
              lg="4"
            >
              <b-form-group :label="$t('pages.account.security.newPassword')">
                <validation-provider
                  #default="{ errors }"
                  name="newPassword"
                  vid="newPassword"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model="newPassword"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      autocomplete="new-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- confirm new password -->
            <b-col
              cols="12"
              lg="4"
            >
              <b-form-group :label="$t('pages.account.security.confirmPassword')">
                <validation-provider
                  #default="{ errors }"
                  name="confirmPassword"
                  vid="confirmPassword"
                  :rules="`required|confirmpassword:${newPassword}`"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model="confirmPassword"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      autocomplete="new-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-button
                variant="primary"
                type="submit"
                :disabled="invalid"
              >
                {{ $t('Save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, password, confirmpassword } from '@foravila-validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { notifyError, notifySuccess } from '@/utils/methods'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      required,
      password,
      confirmpassword,
    }
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('foravilaGuestsUserData'))
    },
    userUuid() {
      return this.userData.uuid
    },
    userEmail() {
      return this.userData.email
    },
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase()
    },
    emptyForm() {
      return !this.currentPassword
        && !this.newPassword
        && !this.confirmPassword
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    currentLanguage() {
      this.switchValidationLocale()
    },
  },
  mounted() {
    this.switchValidationLocale()
  },
  methods: {
    switchValidationLocale() {
      localize(this.currentLanguage)
      if (!this.emptyForm) this.$refs.updatePasswordFormRules.validate()
    },
    resetForm() {
      this.currentPassword = null
      this.newPassword = null
      this.confirmPassword = null
    },
    validateForm() {
      this.$refs.updatePasswordFormRules.validate().then(async success => {
        if (success) {
          this.loading = true
          const correctPassword = await this.$store.dispatch('auth/loginBasic', {
            email: this.userEmail,
            password: this.currentPassword,
          })
            .then(() => true)
            .catch(() => false)

          if (correctPassword) {
            this.$store
              .dispatch('user/updateUser', {
                uuid: this.userUuid,
                plainPassword: this.newPassword,
              })
              .then(() => {
                this.resetForm()
                this.$refs.updatePasswordFormRules.reset()
                notifySuccess(
                  this.$t('pages.account.security.changePasswordSuccessTitle'),
                  this.$t('pages.account.security.changePasswordSuccessText'),
                )
              })
              .catch(() => {
                notifyError(this.$t('errors.changePassword.title'), this.$t('errors.changePassword.text'))
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            this.loading = false
          }
        }
      })
    },
  },
}
</script>
